// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-blog-index-template-js": () => import("./../../../src/templates/BlogIndexTemplate.js" /* webpackChunkName: "component---src-templates-blog-index-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/BlogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-work-post-template-js": () => import("./../../../src/templates/WorkPostTemplate.js" /* webpackChunkName: "component---src-templates-work-post-template-js" */)
}

