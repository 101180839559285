import workqueues from "../../../../src/images/work/uhc/work_queues2.png";
import workqueuessml from "../../../../src/images/work/uhc/work_queues2_small.png";
import layoutimg from "../../../../src/images/work/uhc/table_header_formatting2.png";
import layoutimgsml from "../../../../src/images/work/uhc/table_header_formatting2_small.png";
import appimg from "../../../../src/images/work/uhc/uhc_wide.png";
import appimgsml from "../../../../src/images/work/uhc/uhc_wide_small2.png";
import designsysimg from "../../../../src/images/work/uhc/Inputs.png";
import understanding from "../../../../src/images/work/uhc/UHC_Workflow.png";
import understandingSmall from "../../../../src/images/work/uhc/UHC_Workflow_Small.png";
import layout from "../../../../src/images/work/uhc/UHC_PageLayout.png";
import layoutSmall from "../../../../src/images/work/uhc/UHC_PageLayout_Small.png";
import data from "../../../../src/images/work/uhc/UHC_Table.png";
import dataSmall from "../../../../src/images/work/uhc/UHC_Table_Small.png";
import testing from "../../../../src/images/work/uhc/UHC_Testing.png";
import testingSmall from "../../../../src/images/work/uhc/UHC_Testing_Small.png";
import design from "../../../../src/images/work/uhc/UHC_DesignSystem.png";
import designSmall from "../../../../src/images/work/uhc/UHC_DesignSystem_Small.png";
import * as React from 'react';
export default {
  workqueues,
  workqueuessml,
  layoutimg,
  layoutimgsml,
  appimg,
  appimgsml,
  designsysimg,
  understanding,
  understandingSmall,
  layout,
  layoutSmall,
  data,
  dataSmall,
  testing,
  testingSmall,
  design,
  designSmall,
  React
};