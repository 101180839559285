import wahlsitemap from "../../../../src/images/work/wahl/wahl_sitemap.png";
import wahlsitemapSmall from "../../../../src/images/work/wahl/wahl_sitemap_small.png";
import wahltreejack from "../../../../src/images/work/wahl/wahl_treejack.png";
import wahltreejackSmall from "../../../../src/images/work/wahl/wahl_treejack_small.png";
import wahlwires from "../../../../src/images/work/wahl/wahl_wires.png";
import wahlwiresSmall from "../../../../src/images/work/wahl/wahl_wires_small.png";
import wahlecomm from "../../../../src/images/work/wahl/wahl_ecomm.png";
import wahlecommSmall from "../../../../src/images/work/wahl/wahl_ecomm_small.png";
import * as React from 'react';
export default {
  wahlsitemap,
  wahlsitemapSmall,
  wahltreejack,
  wahltreejackSmall,
  wahlwires,
  wahlwiresSmall,
  wahlecomm,
  wahlecommSmall,
  React
};