import sitemap from "../../../../src/images/work/yamaha/Yamaha_SiteMap.png";
import sitemapSmall from "../../../../src/images/work/yamaha/Yamaha_SiteMap_Small.png";
import perf from "../../../../src/images/work/yamaha/Yamaha_Perf.png";
import perfSmall from "../../../../src/images/work/yamaha/Yamaha_Perf_Small.png";
import dealer from "../../../../src/images/work/yamaha/Yamaha_Dealer.png";
import dealerSmall from "../../../../src/images/work/yamaha/Yamaha_Dealer_Small.png";
import designYMOB from "../../../../src/images/work/yamaha/Yamaha_Design.png";
import designYMOBSmall from "../../../../src/images/work/yamaha/Yamaha_Design_Small.png";
import * as React from 'react';
export default {
  sitemap,
  sitemapSmall,
  perf,
  perfSmall,
  dealer,
  dealerSmall,
  designYMOB,
  designYMOBSmall,
  React
};